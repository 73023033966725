import { AttachComponents } from "components/ProductConfiguration/Components/AttachComponents";
import { VariantComponents } from "components/ProductConfiguration/Components/VariantComponents";
import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import Error from "components/Shared/Error";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";
import React from "react";
import { TemplateComponentProps } from "types";

export const Components = (props: TemplateComponentProps): React.ReactElement => {
  const {
    storeItems,
    handleAttachComponent,
    handleDetachComponent,
    components,
    variants,
    handleVariantComponentsChange,
    measurementUnits,
    loaders,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    variantsHaveChanges,
    name,
    setCreateIsOpen
  } = props;
  return (
    <form className="pb-40" onSubmit={handleFormSubmit} data-testid="componentForm">
      <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
        <Error error={error} />
        <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-0" data-testid="componentDesc">
          Add store items and their usage measurement for these variants.
        </p>
        <AttachComponents
          handleAttachComponent={handleAttachComponent}
          handleDetachComponent={handleDetachComponent}
          storeItems={storeItems}
          components={components.map((component) => ({
            id: component.id,
            name: component.name,
            storeItemId:
              storeItems?.items.find(
                (item) => getStoreItemName(item).toLowerCase() == component.name.toLowerCase()
              )?.id || component.id
          }))}
          name={name}
          setCreateIsOpen={setCreateIsOpen}
        />
        <VariantComponents
          variants={variants}
          variantsHaveChanges={variantsHaveChanges}
          measurementUnits={measurementUnits}
          handleVariantComponentsChange={handleVariantComponentsChange}
          storeItems={storeItems}
          components={components.map((component) => ({
            id: component.id,
            name: component.name,
            storeItemId:
              storeItems?.items.find(
                (item) => getStoreItemName(item).toLowerCase() == component.name.toLowerCase()
              )?.id || component.id
          }))}
        />
      </div>
      <div className="fixed left-0 right-0 bottom-0 bg-white sm:px-12 xl:px-40 py-6 text-center md:text-right z-10">
        <AutosaveButton
          disabled={!hasUnsavedChanges}
          hasChanges={hasUnsavedChanges}
          type="submit"
          loading={loaders.savingComponents}
          data-testid="submitButton"
          className="w-60"
        />
      </div>
    </form>
  );
};

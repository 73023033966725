import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { ERROR_OCCURRED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { extractErrorMessage } from "helpers/api";
import { getDate } from "helpers/date";
import { undefinedToString } from "helpers/string";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FormChangeEvent, PurchaseForm, Response, StoreItemProduct, StoreItemRaw } from "types";
import { AlertType, SegmentEvent } from "types/enum";

export const useStoreItemPurchase = (closeDialog: () => void) => {
  const { storeId } = useParams();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const defaultPurchaseForm: PurchaseForm = {
    price: "",
    measurement: "",
    quantity: "1",
    unit: "",
    storeItemId: undefined
  };

  // UseStates
  const [purchaseForm, setPurchaseForm] = useState<PurchaseForm>(defaultPurchaseForm);

  const [error, setError] = useState("");
  const activeStoreItemId = useMemo(
    () => storeId || purchaseForm.storeItemId,
    [storeId, purchaseForm.storeItemId]
  );

  const handlePurchaseStoreItemChange = (
    event: FormChangeEvent,
    storeItem?: StoreItemRaw | StoreItemProduct
  ): void => {
    const { value } = event.target;
    setPurchaseForm((prev) => ({
      ...prev,
      storeItemId: value,
      unit: undefinedToString(storeItem?.unit.name)
    }));
    setError("");
  };

  const handlePurchaseFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setPurchaseForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const savePurchaseMutation = useMutation({
    mutationFn: async () => {
      const requestData = {
        date: getDate(),
        measurementValue: +purchaseForm.measurement,
        price: +purchaseForm.price,
        quantity: +purchaseForm.quantity,
        unit: purchaseForm.unit //| storeForm?.unit
      };
      const json: Response<string> = await api
        .put(`store/${activeStoreItemId}/stock`, { json: requestData })
        .json();
      return json;
    },
    onSuccess: async (data) => {
      const isSuccessfull = data.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.PURCHASE_ADDED, {
          storeId: activeStoreItemId,
          measurementValue: purchaseForm.measurement,
          price: purchaseForm.price,
          quantity: purchaseForm.quantity
        });
        setPurchaseForm(defaultPurchaseForm);
        showAlert(AlertType.SUCCESS);
        await queryClient.invalidateQueries({ queryKey: ["store_item"] });
        await queryClient.invalidateQueries({ queryKey: ["store_items"] });
        closeDialog();
      } else {
        setError(ERROR_OCCURRED);
      }
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const handlePurchaseFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setError("");
    savePurchaseMutation.mutate();
  };

  return {
    loaders: {
      savingPurchase: false
    },
    error,
    handlePurchaseFormChange,
    purchaseForm,
    handlePurchaseFormSubmit,
    handlePurchaseStoreItemChange
  };
};

export type UseStoreItemPurchaseType = ReturnType<typeof useStoreItemPurchase>;

import React from "react";
import { CreateStoreProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Select from "components/Shared/Select";
import Title from "components/Shared/Title";
import Amount from "components/Shared/Amount/Amount";
import InputGroup from "components/Shared/InputGroup/InputGroup";
import { capitalize } from "helpers/string";
import CurrencySymbol from "components/Shared/CurrencySymbol/CurrencySymbol";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import cx from "classnames";
import { componentIsMatching } from "hooks/useTemplateInstall/useTemplateInstall.utils";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";
import ArrowOutward from "icons/ArrowOutward";

const CreateStoreItem = (props: CreateStoreProps): React.ReactElement => {
  const {
    storeForm,
    handleFormChange,
    handleFormSubmit,
    formIsValid,
    error,
    measurementUnits,
    loaders,
    isEmbedded,
    storeItems
  } = props;
  const matchingStoreItem = storeItems?.items.find((item) =>
    componentIsMatching(item, storeForm.name)
  );
  return (
    <div className={cx("bg-white rounded-lg p-4 ", !isEmbedded && "mobile-full-screen")}>
      {!isEmbedded && (
        <>
          <Link to="/store" className="font-bold text-sm inline-flex" data-testid="backLink">
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back to store items
          </Link>
          <Title className="text-center mt-6 md:mt-0">
            Add from a raw material
            <InfoTooltip>
              Raw materials are store items you purchased externally and use to create your products
            </InfoTooltip>
          </Title>
        </>
      )}
      <form
        onSubmit={handleFormSubmit}
        className={cx("md:mx-12", isEmbedded ? "mt-1 xl:mx-20" : "mt-10 xl:mx-40")}
        data-testid="storeItemForm">
        <Error error={error} />
        <div>
          <TextInput
            type="text"
            name="name"
            onChange={handleFormChange}
            value={storeForm.name}
            required
            label="Item name"
            data-testid="nameInput"
          />
          {matchingStoreItem && (
            <span className="text-grey-60 text-base mb-4 block">
              You already have a similar store item with this name &nbsp;
              <Link
                target="_blank"
                to={`/store/${matchingStoreItem.id}/edit`}
                className="link inline-flex items-end">
                <b>{getStoreItemName(matchingStoreItem)}</b>
                <ArrowOutward width="18" height="18" />
              </Link>
              , consider reusing this to avoid duplicates
            </span>
          )}
        </div>
        <div>
          <InputGroup>
            <div className="w-auto flex-1">
              <TextInput
                type="number"
                name="measurement"
                onChange={handleFormChange}
                value={storeForm.measurement}
                required
                label="Item measurement"
                data-testid="measurementInput"
              />
            </div>
            <div className="w-36 sm:w-40">
              <Select
                id="unit"
                name="unit"
                value={storeForm.unit}
                required
                onChange={handleFormChange}
                data-testid="measurementUnit"
                className="bg-soft-grey"
                label="Unit">
                <option value="" disabled>
                  Select a unit
                </option>
                {measurementUnits.map((measurementUnit) => (
                  <option key={measurementUnit.name} value={measurementUnit.name}>{`${capitalize(
                    measurementUnit.category
                  )}: ${measurementUnit.symbol}`}</option>
                ))}
              </Select>
            </div>
          </InputGroup>
        </div>
        <div>
          <TextInput
            type="number"
            name="quantity"
            id="quantity"
            onChange={handleFormChange}
            value={storeForm.quantity}
            required={true}
            label="How many did you buy?"
            data-testid="quantityInput"
            step={1}
          />
        </div>
        <div>
          <TextInput
            type="number"
            name="price"
            id="price"
            value={storeForm.price}
            required={true}
            onChange={handleFormChange}
            label="Total amount"
            data-testid="amountInput"
            prepend={
              <span className="text-xl">
                <CurrencySymbol />
              </span>
            }
          />
        </div>
        <div>
          <p>
            Cost per unit:&nbsp;
            <Amount amount={+storeForm.price / (+storeForm.quantity * +storeForm.measurement)} />
            &nbsp; per &nbsp;
            {measurementUnits.find((unit) => unit.name == storeForm.unit)?.symbol}
          </p>
          <p>
            In stock:&nbsp;
            <b>
              {+storeForm.quantity * +storeForm.measurement}
              {measurementUnits.find((unit) => unit.name == storeForm.unit)?.symbol}
            </b>
          </p>
        </div>
        <Button
          type="submit"
          data-testid="submitButton"
          disabled={!formIsValid}
          loading={loaders.savingStoreItem}
          className="w-full mt-6">
          Save
        </Button>
      </form>
    </div>
  );
};

export default CreateStoreItem;

import Accordion from "components/Shared/Accordion";
import Amount from "components/Shared/Amount";
import Autocomplete from "components/Shared/Autocomplete";
import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import CurrencySymbol from "components/Shared/CurrencySymbol/CurrencySymbol";
import Error from "components/Shared/Error";
import IconButton from "components/Shared/IconButton";
import Tag from "components/Shared/Tag/Tag";
import TextInput from "components/Shared/TextInput";
import ArrowLeft from "icons/ArrowLeft";
import ArrowRight from "icons/ArrowRight";
import Info from "icons/Info";
import React, { useState } from "react";
import { ProductAddOnProps } from "types";
import { AddOnForm } from "./AddonForm";
import Plus from "icons/Plus";

const AddOns = (props: ProductAddOnProps): React.ReactElement => {
  const {
    addOnProps,
    handleAddAddOn,
    handleRemoveAddOn,
    productVariantAddOns,
    handleVariantAddOnsChange,
    productAddOns,
    loaders,
    handleCostFormSubmit,
    error,
    hasUnsavedChanges,
    variantsHaveChanges,
    productName
  } = props;
  const { businessAddOns } = addOnProps;
  const [activeKey, setActiveKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <form className="pb-40" onSubmit={handleCostFormSubmit} data-testid="addOnForm">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <Error error={error} />
          <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-0" data-testid="componentDesc">
            Add extras to your variants that you sell along with the product.
          </p>
          <div>
            <Autocomplete
              type="text"
              name="addOns"
              onDataSelected={(data) => {
                if (data) {
                  handleAddAddOn(data.name);
                }
              }}
              value=""
              label="Add Add-on"
              data-testid="addOns"
              dataIdName="addOnId"
              data={
                businessAddOns
                  .filter(
                    (businessAddOn) =>
                      !productAddOns.find(
                        (productAddOn) => businessAddOn.id === productAddOn.businessAddOnId
                      )
                  )
                  .map((item) => ({ id: item.id, name: item.name })) || []
              }
              placeholder="Start typing to filter business add-ons"
              defaultData={
                <span className="text-grey-50 text-sm">
                  Can’t see what you are looking for?&nbsp;{" "}
                  <button
                    type="button"
                    className="link inline-flex"
                    onClick={() => setIsOpen(true)}>
                    <b>Create a new add-on</b>
                  </button>
                </span>
              }
            />
            {/* <button
              type="button"
              className="flex gap-1 items-center link cursor-pointer mb-2"
              onClick={() => setIsOpen(true)}>
              <Plus />
              <b>Create a new add-on</b>
            </button> */}
            <div>
              <p className="font-bold">Add-ons in use</p>
              <div className="flex mt-4 flex-wrap" data-testid="productAddOns">
                {productAddOns.map((addOn) => (
                  <Tag
                    key={addOn.id}
                    name={addOn.name}
                    onRemove={() => handleRemoveAddOn(addOn.id)}
                  />
                ))}
              </div>
            </div>
          </div>
          <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-6" data-testid="">
            {productAddOns.length > 0
              ? `Set the add-on costs for ${productName} variants.`
              : "No add-ons in use."}
          </p>
          {productVariantAddOns.map(
            (productVariantAddOn, productVariantAddOnIndex): React.ReactElement => (
              <Accordion
                key={productVariantAddOn.variantId}
                itemId={productVariantAddOn.variantId}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                header={
                  <div className="inline-flex items-center justify-between flex-1 pr-6">
                    <span>
                      <b data-testid="variantName">{productVariantAddOn.variantName}</b>
                      {productVariantAddOn.addOnCosts.filter((addOnCost) => addOnCost.cost == 0)
                        .length > 0 && (
                        <span
                          className="ml-4 text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center"
                          data-testid="emptyAddOnCosts">
                          <Info /> &nbsp;
                          {
                            productVariantAddOn.addOnCosts.filter(
                              (addOnCost) => addOnCost.cost == 0
                            ).length
                          }
                        </span>
                      )}
                    </span>
                    {!variantsHaveChanges[productVariantAddOn.variantId] && (
                      <Amount amount={productVariantAddOn.totalCost} />
                    )}
                  </div>
                }>
                <div className="flex flex-col">
                  {productVariantAddOn.addOnCosts.map((addOnCost) => (
                    <div
                      className="flex justify-between items-center pt-2 px-2 md:px-4  border-b border-grey-30"
                      key={addOnCost.addOnId}
                      data-testid="addOnCost">
                      <span data-testid="addOnName" className="flex-1">
                        {
                          productAddOns.find((productAddOn) => productAddOn.id == addOnCost.addOnId)
                            ?.name
                        }
                      </span>
                      <div className="flex-1 max-w-xs">
                        <TextInput
                          type="number"
                          name="cost"
                          id="amount"
                          value={addOnCost.cost}
                          required={true}
                          onChange={(e): void =>
                            handleVariantAddOnsChange(
                              e,
                              productVariantAddOn.variantId,
                              addOnCost.addOnId
                            )
                          }
                          label={
                            <>
                              Cost <Amount amount={+addOnCost.cost} />
                            </>
                          }
                          data-testid="amountInput"
                          inputSize="sm"
                          prepend={
                            <span className="text-md">
                              <CurrencySymbol />
                            </span>
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-between items-center py-2 px-4 border-grey-30 bg-soft-grey text-primary">
                    <div>
                      {productVariantAddOnIndex > 0 && (
                        <IconButton
                          size="sm"
                          onClick={() =>
                            setActiveKey(
                              productVariantAddOns[productVariantAddOnIndex - 1].variantId
                            )
                          }
                          icon={<ArrowLeft />}
                          data-testid="prev"
                          outlined>
                          Previous
                        </IconButton>
                      )}
                    </div>
                    <div>
                      {productVariantAddOnIndex < productVariantAddOns.length - 1 && (
                        <IconButton
                          size="sm"
                          onClick={() =>
                            setActiveKey(
                              productVariantAddOns[productVariantAddOnIndex + 1].variantId
                            )
                          }
                          iconEnd={<ArrowRight />}
                          data-testid="next"
                          outlined>
                          Next variant
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion>
            )
          )}
        </div>
        <div className="fixed left-0 right-0 bottom-0 bg-white sm:px-12 xl:px-40 py-6 text-center md:text-right z-10">
          <AutosaveButton
            disabled={!hasUnsavedChanges}
            hasChanges={hasUnsavedChanges}
            type="submit"
            loading={loaders.savingAddOns}
            data-testid="submitButton"
            className="w-60"
          />
        </div>
      </form>
      <AddOnForm
        {...addOnProps}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleFormSubmit={addOnProps.handleProductFormSubmit}
      />
    </div>
  );
};

export default AddOns;

import { TextInputProps } from "types";
import React, { forwardRef } from "react";
import "./TextInput.css";
import cx from "classnames";

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props: TextInputProps, ref): React.ReactElement => {
    const {
      label,
      inputSize,
      multiLine = false,
      prepend,
      append,
      simple = false,
      error,
      showPlaceHolder,
      ...inputProps
    } = props;
    return (
      <div
        className={cx(
          "input-main",
          inputSize || "md",
          inputProps.disabled && "disabled",
          !label && "no-label"
        )}
        data-testid="inputContainer">
        <div className="input-box">
          {multiLine ? (
            <textarea
              data-testid="multiLineTextInput"
              id={props.name}
              className={cx("input", inputProps.className, {
                "has-prepend": prepend,
                "has-append": append,
                "show-placeholder": showPlaceHolder,
                error: error
              })}
              onChange={(e): void => props.onChange?.(e)}
              rows={4}
              placeholder=" "
              {...inputProps}>
              {props.value}
            </textarea>
          ) : (
            <input
              autoComplete="none"
              data-testid="textInput"
              id={props.name}
              type="text"
              placeholder=" "
              min={1}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.key === "Enter" && e.preventDefault();
              }}
              step=".01"
              {...inputProps}
              className={cx("input", inputProps.className, {
                "has-prepend": prepend,
                "has-append": append,
                "show-placeholder": showPlaceHolder && !label,
                error: error
              })}
              ref={ref}
              onFocus={(e) => {
                if (inputProps.type === "number" && e.target.value == "0") {
                  inputProps.onChange?.({
                    ...e,
                    target: {
                      ...e.target,
                      value: "",
                      name: inputProps.name
                    }
                  });
                }
                inputProps.onFocus?.(e);
              }}
              onBlur={(e): void => {
                if (inputProps.type === "number" && !inputProps.value) {
                  inputProps.onChange?.({
                    ...e,
                    target: {
                      ...e.target,
                      value: "0",
                      name: inputProps.name
                    }
                  });
                }
                if (
                  inputProps.type === "number" &&
                  inputProps.min !== undefined &&
                  inputProps.value !== undefined &&
                  +inputProps.value < +inputProps.min
                ) {
                  inputProps.onChange?.({
                    ...e,
                    target: {
                      ...e.target,
                      value: `${inputProps.min}`,
                      name: inputProps.name
                    }
                  });
                }
                if (
                  inputProps.type === "number" &&
                  inputProps.max !== undefined &&
                  inputProps.value !== undefined &&
                  +inputProps.value > +inputProps.max
                ) {
                  inputProps.onChange?.({
                    ...e,
                    target: {
                      ...e.target,
                      value: `${inputProps.max}`,
                      name: inputProps.name
                    }
                  });
                }
                inputProps.onBlur?.(e);
              }}
            />
          )}
          {label && (
            <label htmlFor={props.name} className="label" data-testid="inputLabel">
              {label} {props.required && <span className="text-red-500"> *</span>}
            </label>
          )}
          {!simple && <div className="input-underline" />}
          {prepend && (
            <span className="absolute left-0 top-0 bottom-0 z-[2] p-4 pt-5 prepend">{prepend}</span>
          )}
          {append && (
            <span className="absolute right-0 top-0 bottom-0 z-[2] p-4 pt-5 prepend">{append}</span>
          )}
        </div>
      </div>
    );
  }
);
TextInput.displayName = "TextInput";

export default TextInput;

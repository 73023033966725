import Autocomplete from "components/Shared/Autocomplete";
import Button from "components/Shared/Button";
import Stepper from "components/Shared/Stepper";
import SubTitle from "components/Shared/SubTitle";
import Title from "components/Shared/Title";
import { undefinedToString } from "helpers/string";
import ArrowLeft from "icons/ArrowLeft";
import React from "react";
import { ReviewTemplateProps } from "types";

export const ProductSettings = ({
  template,
  setCompletedReviews,
  setActiveTemplate,
  activeStep,
  setActiveStep,
  activeTemplate,
  storeItems,
  businessActivities,
  businessAddOns,
  installation,
  handleInstallationComponentChange,
  handleInstallationAddOnChange,
  handleInstallationActivityChange
}: ReviewTemplateProps): React.ReactElement => {
  const steps = [
    ...(activeTemplate!.components.length > 0 ? ["Review components"] : []),
    ...(activeTemplate!.addOns.length > 0 ? ["Review addons"] : []),
    ...(activeTemplate!.activities.length > 0 ? ["Review activities"] : [])
  ];
  const activeStepIndex = steps.findIndex((step) => step == activeStep);

  const isMainProduct = activeTemplate?.id == installation.templateId;
  const linkedTemplateInstallation = installation.linkedTemplates.find(
    (linkedTemplate) => linkedTemplate.templateId == activeTemplate?.id
  );

  return (
    <div className="bg-white rounded-lg">
      <div className="p-4 lg:p-10 w-full border-b border-soft-grey">
        <span
          className="font-bold text-sm inline-flex cursor-pointer"
          data-testid="backLink"
          onClick={() => setActiveTemplate(undefined)}>
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to templates
        </span>
        <div className="text-center mb-8">
          <Title className="text-center">Product settings</Title>
          <Stepper setActiveStep={setActiveStep} activeStep={activeStep} steps={steps} />
          <SubTitle className="text-grey-50 py-6">
            Review the items that will be added to your store to avoid duplicate records.
          </SubTitle>
          {activeStep == "Review components" &&
            activeTemplate?.components.map((component, componentIndex) => {
              const installationComponent = isMainProduct
                ? installation.components.find(
                    (installationComponent) => component.id == installationComponent.id
                  )
                : linkedTemplateInstallation?.components.find(
                    (installationComponent) => component.id == installationComponent.id
                  );
              const activeTemplateComponent = activeTemplate.components.find(
                (component) => component.id == installationComponent?.id
              );
              const items = storeItems
                .filter(
                  (item) => item.unit.category == activeTemplateComponent?.measurementUnit.category
                )
                .map((item) => ({
                  id: item.id,
                  name:
                    item.type == "RAW_MATERIAL"
                      ? item.name
                      : `${item.productName} - ${item.variantName}`
                }));
              return (
                <div
                  key={component.id}
                  className="flex flex-wrap justify-between items-center bg-soft-grey rounded-lg px-6 pb-2 pt-4 my-4 gap-4">
                  <p className="font-bold text-xl w-48 mb-2 text-left" data-testid="productsName">
                    {component?.name}
                  </p>
                  <div className="flex-grow">
                    <Autocomplete
                      name="replaced"
                      value={
                        installationComponent?.replaced
                          ? "Link to an existing store item"
                          : "Add as a new store item"
                      }
                      onDataSelected={(data) => {
                        if (data) {
                          handleInstallationComponentChange(
                            data,
                            "replaced",
                            componentIndex,
                            undefinedToString(activeTemplate?.id)
                          );
                        }
                      }}
                      label=""
                      data={[
                        { id: "new", name: "Add as a new store item" },
                        { id: "link", name: "Link to an existing store item" }
                      ]}
                      inputSize="sm"
                      disableNew
                      placeholder={undefined}
                      disableInput
                    />
                  </div>
                  {installationComponent?.replaced && (
                    <div className="flex-grow">
                      <Autocomplete
                        name="storeItemId"
                        onDataSelected={(data) => {
                          if (data) {
                            handleInstallationComponentChange(
                              data,
                              "storeItemId",
                              componentIndex,
                              undefinedToString(activeTemplate?.id)
                            );
                          }
                        }}
                        value={
                          items.find((item) => item.id == installationComponent?.storeItemId)?.name
                        }
                        label=""
                        dataIdName="storeItemId"
                        data={items}
                        inputSize="sm"
                        disableNew
                        placeholder="Start typing to filter components"
                        disableInput
                      />
                    </div>
                  )}
                </div>
              );
            })}
          {activeStep == "Review addons" &&
            activeTemplate?.addOns.map((addOn, addOnIndex) => {
              const installationAddOn = isMainProduct
                ? installation.addOns.find((installationAddOn) => addOn.id == installationAddOn.id)
                : linkedTemplateInstallation?.addOns.find(
                    (installationAddOn) => addOn.id == installationAddOn.id
                  );
              return (
                <div
                  key={addOn.id}
                  className="flex flex-wrap justify-between items-center bg-soft-grey rounded-lg px-6 pb-2 pt-4 my-4 gap-4">
                  <p className="font-bold text-xl w-48 mb-2 text-left" data-testid="productsName">
                    {addOn?.name}
                  </p>
                  <div className="flex-grow">
                    <Autocomplete
                      name="replaced"
                      value={
                        installationAddOn?.replaced
                          ? "Link to an existing addon"
                          : "Add as a new addon"
                      }
                      onDataSelected={(data) => {
                        if (data) {
                          handleInstallationAddOnChange(
                            data,
                            "replaced",
                            addOnIndex,
                            undefinedToString(activeTemplate?.id)
                          );
                        }
                      }}
                      label=""
                      data={[
                        { id: "new", name: "Add as a new addon" },
                        { id: "link", name: "Link to an existing addon" }
                      ]}
                      inputSize="sm"
                      disableNew
                      placeholder={undefined}
                      disableInput
                    />
                  </div>
                  {installationAddOn?.replaced && (
                    <div className="flex-grow">
                      <Autocomplete
                        name="addOn"
                        onDataSelected={(data) => {
                          if (data) {
                            handleInstallationAddOnChange(
                              data,
                              "businessAddOnId",
                              addOnIndex,
                              undefinedToString(activeTemplate?.id)
                            );
                          }
                        }}
                        value={
                          businessAddOns.find(
                            (addOn) => addOn.id == installationAddOn?.businessAddOnId
                          )?.name
                        }
                        label=""
                        dataIdName="businessAddOnId"
                        data={businessAddOns}
                        inputSize="sm"
                        disableNew
                        placeholder="Start typing to filter addons"
                        disableInput
                      />
                    </div>
                  )}
                </div>
              );
            })}
          {activeStep == "Review activities" &&
            activeTemplate?.activities.map((activity, activityIndex) => {
              const installationActivity = isMainProduct
                ? installation.activities.find(
                    (installationActivity) => activity.id == installationActivity.id
                  )
                : linkedTemplateInstallation?.activities.find(
                    (installationActivity) => activity.id == installationActivity.id
                  );
              return (
                <div
                  key={activity.id}
                  className="flex flex-wrap justify-between items-center bg-soft-grey rounded-lg px-6 pb-2 pt-4 my-4 gap-4">
                  <p className="font-bold text-xl w-48 mb-2 text-left" data-testid="productsName">
                    {activity?.name}
                  </p>
                  <div className="flex-grow">
                    <Autocomplete
                      name="replaced"
                      value={
                        installationActivity?.replaced
                          ? "Link to an existing activity"
                          : "Add as a new activity"
                      }
                      onDataSelected={(data) => {
                        if (data) {
                          handleInstallationActivityChange(
                            data,
                            "replaced",
                            activityIndex,
                            undefinedToString(activeTemplate?.id)
                          );
                        }
                      }}
                      label=""
                      data={[
                        { id: "new", name: "Add as a new activity" },
                        { id: "link", name: "Link to an existing activity" }
                      ]}
                      inputSize="sm"
                      disableNew
                      placeholder={undefined}
                      disableInput
                    />
                  </div>
                  {installationActivity?.replaced && (
                    <div className="flex-grow">
                      <Autocomplete
                        name="activity"
                        onDataSelected={(data) => {
                          if (data) {
                            handleInstallationActivityChange(
                              data,
                              "businessActivityId",
                              activityIndex,
                              undefinedToString(activeTemplate?.id)
                            );
                          }
                        }}
                        value={
                          businessActivities.find(
                            (addOn) => addOn.id == installationActivity?.businessActivityId
                          )?.name
                        }
                        label=""
                        dataIdName="businessActivityId"
                        data={businessActivities}
                        inputSize="sm"
                        disableNew
                        placeholder="Start typing to filter activities"
                        disableInput
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="p-4 lg:p-10 w-full flex justify-end gap-4">
        <Button
          className="bg-black"
          gradient={false}
          onClick={() => {
            if (activeStepIndex == 0) {
              setActiveTemplate(undefined);
            } else {
              setActiveStep(steps[activeStepIndex - 1]);
            }
          }}>
          Go back
        </Button>
        <Button
          onClick={() => {
            if (activeStepIndex == steps.length - 1) {
              setActiveTemplate(undefined);
              setCompletedReviews((prev) => ({
                ...prev,
                [undefinedToString(activeTemplate?.id)]: true
              }));
            } else {
              setActiveStep(steps[activeStepIndex + 1]);
            }
          }}>
          Continue
        </Button>
      </div>
    </div>
  );
};

import { QueryObserverResult, RefetchOptions, UseMutateFunction } from "@tanstack/react-query";
import { useAlert } from "context/alert/AlertContext";
import { useState } from "react";
import { AlertType } from "types/enum";
import { Response } from "types";

export const useDelete = <T, K>({
  getMessage,
  handleDelete,
  refetch
}: {
  getMessage: (data: T) => string;
  handleDelete:
    | ((data: T) => Promise<boolean>)
    | UseMutateFunction<K | undefined, Error, T, unknown>;
  refetch?:
    | (() => Promise<void> | void)
    | ((options?: RefetchOptions) => Promise<QueryObserverResult<any, any>>);
}) => {
  const { showAlert } = useAlert();
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState("");
  const [itemToBeDeleted, setItemToBeDeleted] = useState<T>();

  const handleConfirmDelete = (data: T): void => {
    setDeleteConfirmationMessage(getMessage(data));
    setItemToBeDeleted(data);
  };

  const handleDeleteConfirmation = async (autoCancel = true): Promise<void> => {
    if (!itemToBeDeleted) {
      return;
    }

    try {
      if (await handleDelete(itemToBeDeleted)) {
        showAlert(AlertType.SUCCESS);
      } else {
        showAlert(AlertType.DANGER);
      }
    } catch {
      //
    }
    if (autoCancel) {
      handleDeleteCancellation();
    }
    await refetch?.();
  };

  const handleDeleteCancellation = (): void => {
    setDeleteConfirmationMessage("");
    setItemToBeDeleted(undefined);
  };

  return {
    deleteConfirmationMessage,
    handleConfirmDelete,
    handleDeleteConfirmation,
    handleDeleteCancellation,
    itemToBeDeleted,
    setDeleteConfirmationMessage
  };
};

export type UseDeleteType<T, K> = ReturnType<typeof useDelete<T, K>>;

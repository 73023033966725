import Amount from "components/Shared/Amount";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import DateFilter from "components/Shared/DateFilter";
import Disclosure from "components/Shared/Disclosure";
import Dropdown from "components/Shared/Dropdown";
import IconButton from "components/Shared/IconButton";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import Sort from "components/Shared/Sort";
import Table from "components/Shared/Table";
import Title from "components/Shared/Title";
import { formatMonthRange } from "helpers/date";
import CreditCard from "icons/CreditCard";
import Customize from "icons/Customize";
import Delete from "icons/Delete";
import Edit from "icons/Edit";
import Info from "icons/Info";
import Kebeb from "icons/Kebeb";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";
import { OrdersProps } from "types";

const Orders = (props: OrdersProps): React.ReactElement => {
  const {
    orders,
    ordersSummary,
    dateRange,
    handleDateRangeChange,
    handleConfirmDelete,
    deleteConfirmationMessage,
    handleDeleteConfirmation,
    handleDeleteCancellation,
    sort
  } = props;

  return (
    <div className="">
      <div className="flex justify-between items-center px-4 md:px-0">
        <Title className="pt-2 pb-6">
          Orders
          <InfoTooltip>These are the sales of your products or service to customers.</InfoTooltip>
        </Title>
        <Link className="block sm:hidden" to="create">
          <IconButton icon={<Plus />} className="mb-2">
            Add new order
          </IconButton>
        </Link>
      </div>
      <div className="flex flex-col-reverse sm:flex-row justify-between items-center px-4 md:px-0">
        <DateFilter
          dateRange={dateRange}
          handleDateRangeChange={(date): void => handleDateRangeChange(date)}
        />
        <Link className="hidden sm:block" to="create">
          <IconButton icon={<Plus />} className="mb-2">
            Add new order
          </IconButton>
        </Link>
      </div>
      <div className="bg-white md:rounded-lg p-4 lg:p-6 mt-2 sm:mt-7">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 text-white">
          <div className="w-full rounded bg-gradient-to-r from-primary to-secondary p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base	font-semibold">Total orders</p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">
                <Amount amount={ordersSummary?.totalAmount} />
              </h2>
            </div>
            <CreditCard />
          </div>
          <div className="w-full rounded bg-grey-60 p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base	font-semibold">Total amount due</p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">
                <Amount amount={ordersSummary?.totalAmountDue} />
              </h2>
            </div>
            <CreditCard />
          </div>
        </div>
        <div className="hidden sm:block">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadData {...sort} field="ref">
                  Order ID
                </Table.HeadData>
                <Table.HeadData {...sort} field="customerName">
                  Customer
                </Table.HeadData>
                <Table.HeadData {...sort} field="amountTotal">
                  Total
                </Table.HeadData>
                <Table.HeadData {...sort} field="amountDue">
                  Amount Due
                </Table.HeadData>
                <Table.HeadData {...sort} field="date">
                  Date
                </Table.HeadData>
                <Table.HeadData />
              </Table.Row>
            </Table.Head>
            <Table.Body className="text-dark-grey" data-testid="orders">
              {orders.map(
                (order): React.ReactElement => (
                  <Table.Row
                    key={order.id}
                    className="rounded-lg"
                    data-testid="order"
                    to={`${order.id}`}>
                    <Table.Data data-testid="orderId">#{order.ref}</Table.Data>
                    <Table.Data data-testid="customerName">{order.customerName}</Table.Data>
                    <Table.Data className="font-bold" data-testid="amountTotal">
                      <Amount amount={order.amountTotal} />
                    </Table.Data>
                    <Table.Data className="font-bold" data-testid="amountDue">
                      <Amount amount={order.amountDue} />
                    </Table.Data>
                    <Table.Data data-testid="orderDate">
                      {new Date(order.date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric"
                      })}
                    </Table.Data>
                    <Table.Data className="text-dark-grey">
                      <Dropdown
                        menu={[
                          {
                            text: "Generate invoice",
                            icon: <Customize />,
                            link: `${order.id}/invoice`
                          },
                          {
                            text: "Delete",
                            icon: <Delete />,
                            onClick: () => handleConfirmDelete(order)
                          }
                        ]}>
                        <Kebeb />
                      </Dropdown>
                    </Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="block sm:hidden mt-4">
          <Sort
            {...sort}
            fields={[
              { field: "ref", name: "Order ID" },
              { field: "customerName", name: "Customer" },
              { field: "amountTotal", name: "Total" },
              { field: "amountDue", name: "Amount Due" },
              { field: "date", name: "Date" }
            ]}
          />
          <div className="flex">
            <span className="font-bold text-grey-50 w-2/5 px-4">Customer</span>
            <span className="font-bold text-grey-50 w-2/5 px-4">Total</span>
            <span className="w-1/5"></span>
          </div>
          {orders.map(
            (order): React.ReactElement => (
              <Disclosure key={order.ref} data-testid="orderMobile">
                <Link className="flex" to={`${order.id}`}>
                  <span className="font-bold w-1/2" data-testid="customerName">
                    {order.customerName}
                  </span>
                  <span className="font-bold w-1/2" data-testid="amountTotal">
                    <Amount amount={order.amountTotal} />
                  </span>
                </Link>
                <div className="flex flex-col">
                  <small className="mt-2" data-testid="orderId">
                    <span className="font-bold w-24 inline-flex">Order ID:</span> #{order.ref}
                  </small>
                  <small className="mb-2" data-testid="">
                    <span className="font-bold w-24 inline-flex">Amount due:</span>
                    <Amount amount={order.amountDue} />
                  </small>
                  <span className="flex flex-wrap">
                    <Link to={`${order.id}`} className="mobile-link">
                      <Info className="mobile-icon" />
                      <span>View</span>
                    </Link>
                    <Link to={`${order.id}/edit`} className="mobile-link">
                      <Edit className="mobile-icon" />
                      <span>Edit</span>
                    </Link>
                    <button
                      data-testid="confirmDelete"
                      onClick={(): void => handleConfirmDelete(order)}
                      className="text-red-500 mobile-link">
                      <Delete className="mobile-icon" />
                      <span>Delete</span>
                    </button>
                    <Link to={`${order.id}/invoice`} className="mobile-link w-40">
                      <Customize className="mobile-icon" />
                      <span>Generate invoice</span>
                    </Link>
                  </span>
                </div>
              </Disclosure>
            )
          )}
        </div>
        {orders.length == 0 && (
          <div data-testid="noOrders" className="p-4 md:px-6">
            No orders created for {formatMonthRange(dateRange)}
          </div>
        )}
      </div>
      <ConfirmationDialog
        message={deleteConfirmationMessage}
        handleContinue={handleDeleteConfirmation}
        handleCancel={handleDeleteCancellation}
      />
    </div>
  );
};

export default Orders;

import ProductDetail from "components/ProductDetail";
import useProduct from "hooks/useProduct";
import React from "react";
import LoadingComponent from "components/Shared/Loading";

const ViewProductPage = (): React.ReactElement => {
  const { loaders, productDetail, variants, sort, deleteVariant } = useProduct();

  return (
    <LoadingComponent loading={loaders.fetchingProduct && !productDetail}>
      {productDetail && (
        <ProductDetail
          productDetail={productDetail}
          variants={variants}
          sort={sort}
          deleteVariant={deleteVariant}
        />
      )}
    </LoadingComponent>
  );
};

export default ViewProductPage;

import api from "config/api";
import { useAppState } from "config/store";
import { ERROR_OCCURRED, FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { startOfMonth } from "date-fns";
import { track } from "helpers/analytics";
import { formatDate, formatFirstOfMonthToISODate, getMonth } from "helpers/date";
import { isNotEmpty, isNumber } from "helpers/validate";
import useDateRange from "hooks/shared/useDateRange";
import { useDelete } from "hooks/shared/useDelete/useDelete";
import useActivity from "hooks/useActivity";
import useSort from "hooks/useSort";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Expense,
  Expenses,
  ExpenseType,
  ExpenseWithActivityAllocation,
  FormChangeEvent,
  Response
} from "types";
import { ExpenseForm, ExpenseLoaders, UseExpenseType } from "types";
import { AlertType, ExpenseCategory, Period, SegmentEvent } from "types/enum";

const useExpense = (): UseExpenseType => {
  const navigate = useNavigate();
  const appState = useAppState();
  const { showAlert } = useAlert();
  const { expenseId } = useParams();
  const { dateRange, handleDateRangeChange, getDateFilter } = useDateRange();
  const { businessActivities } = useActivity();

  // UseStates
  const [expenseForm, setExpenseForm] = useState<ExpenseForm>({
    expenseCategory: "",
    name: "",
    amount: "",
    isRecurring: false,
    startDate: getMonth(startOfMonth(new Date())),
    period: "",
    activities: []
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<ExpenseLoaders>({
    savingExpense: false,
    fetchingExpense: true
  });
  const [error, setError] = useState("");
  const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);
  const [expensesSummary, setExpensesSummary] = useState<Expenses>();
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [expense, setExpense] = useState<Expense>();
  const [administrativeActivityId, setAdministrativeActivityId] = useState<string>();
  const [expensesActivityAllocation, setExpensesActivityAllocation] = useState<
    ExpenseWithActivityAllocation[]
  >([]);
  const [reusedExpense, setReusedExpense] = useState<ExpenseWithActivityAllocation>();
  const sort = useSort(setExpenses);

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setExpenseForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleActivityAllocationChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;

    setExpenseForm((prev) => {
      const administrativeAllocation =
        prev.activities.find((activity) => activity.activityId == administrativeActivityId)
          ?.allocation || 0;

      const existingAllocation =
        prev.activities.find((activity) => activity.activityId == name)?.allocation || 0;

      const newAdministrativeAllocation = existingAllocation - +value + administrativeAllocation;

      if (administrativeAllocation <= 0 && existingAllocation < +value) {
        return prev;
      }
      return {
        ...prev,
        activities: [
          ...prev.activities.filter(
            (activity) =>
              activity.activityId !== name && activity.activityId != administrativeActivityId
          ),
          {
            activityId: name,
            allocation:
              newAdministrativeAllocation < 0 ? +value + newAdministrativeAllocation : +value
          },
          {
            activityId: administrativeActivityId || "",
            allocation:
              newAdministrativeAllocation < 0
                ? 0
                : existingAllocation - +value + administrativeAllocation
          }
        ]
      };
    });
  };

  const handleFormToggleChange = (data: Partial<ExpenseForm>): void => {
    setExpenseForm((prev) => ({
      ...prev,
      ...data
    }));
    setError("");
  };

  const handleCreateExpense = async (): Promise<boolean> => {
    const requestData = {
      businessId: appState.business?.get()?.id,
      name: expenseForm.name,
      expenseCategory: expenseForm.expenseCategory,
      otherExpenseCategory: expenseForm.otherExpenseCategory,
      period: expenseForm.period,
      amount: parseFloat(expenseForm.amount),
      date: formatFirstOfMonthToISODate(expenseForm.startDate),
      isRecurring: expenseForm.isRecurring,
      endDate: expenseForm.endDate ? formatFirstOfMonthToISODate(expenseForm.endDate) : "",
      activitiesAllocation: expenseForm.activities
    };

    try {
      const json: Response<undefined> = await api.post("expense", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.EXPENSE_ADDED, {
          isRecurring: expenseForm.isRecurring,
          period: expenseForm.period,
          expenseCategory: expenseForm.expenseCategory,
          expenseId: json.data
        });
        showAlert(AlertType.SUCCESS);
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateExpense = async (): Promise<boolean> => {
    const requestData = {
      name: expenseForm.name,
      isRecurring: expenseForm.isRecurring,
      amount: parseFloat(expenseForm.amount),
      date: formatFirstOfMonthToISODate(expenseForm.startDate),
      endDate: expenseForm.endDate ? formatFirstOfMonthToISODate(expenseForm.endDate) : "",
      activitiesAllocation: expenseForm.activities,
      period: expenseForm.period
    };

    try {
      const json: Response<void> = await api
        .put(`expense/${expenseId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.EXPENSE_MODIFIED, {
          isRecurring: expenseForm.isRecurring,
          period: expenseForm.period,
          expenseCategory: expenseForm.expenseCategory,
          expenseId
        });
        showAlert(AlertType.SUCCESS);
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleDeleteExpense = async (expense: Expense): Promise<boolean> => {
    try {
      const json: Response<void> = await api.delete(`expense/${expense.id}`).json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingExpense: true }));
    let result;
    if (expenseId) {
      result = await handleUpdateExpense();
    } else {
      result = await handleCreateExpense();
    }
    setLoaders((prev) => ({ ...prev, savingExpense: false }));
    if (result) {
      navigate("/expenses");
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleGetExpenseTypes = async (): Promise<void> => {
    try {
      const json: Response<ExpenseType[]> = await api.get("expense/types").json();
      if (json.code === 200) {
        setExpenseTypes(json.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetExpenses = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingExpense: true }));

    try {
      const json: Response<Expenses & { expenses: Expense[] }> = await api
        .get(`expense?${getDateFilter()}`)
        .json();
      if (json.code === 200) {
        setExpensesSummary(json.data);
        setExpenses(json.data.expenses);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingExpense: false }));
  };

  const handleGetExpense = async (expenseId: string): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingExpense: true }));

    try {
      const json: Response<Expense> = await api.get(`expense/${expenseId}`).json();
      if (json.code === 200) {
        const { name, amountTotal, isRecurring, startDate, endDate, activities, period } =
          json.data;
        setExpenseForm({
          expenseCategory: "",
          period,
          name,
          amount: amountTotal.toString(),
          isRecurring,
          startDate: getMonth(new Date(startDate.slice(0, -1))),
          endDate: formatDate(new Date(endDate.slice(0, -1))),
          activities
        });

        setExpense(json.data);
      }
    } catch (err) {
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingExpense: false }));
  };

  const handleGetExpensesWithActivityAllocation = async (): Promise<void> => {
    try {
      const json: Response<ExpenseWithActivityAllocation[]> = await api
        .get("expense/activities")
        .json();
      if (json.code === 200) {
        setExpensesActivityAllocation(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
  };

  const handleReuseExpenseAllocation = (expenseId: string) => {
    const selectedExpense = expensesActivityAllocation.find((expense) => expense.id == expenseId);
    setReusedExpense(selectedExpense);
    if (selectedExpense) {
      setExpenseForm((prev) => ({
        ...prev,
        activities: selectedExpense.activities || []
      }));
    }
  };

  const handleSort = (field: string): void => {
    sort.sort(expenses, field as keyof Expense);
  };

  // UseEffects
  useEffect(() => {
    const isCreatingNewExpense = !expenseId;
    const categoryIsValid =
      !isCreatingNewExpense ||
      (isCreatingNewExpense &&
        isNotEmpty(expenseForm.expenseCategory) &&
        (expenseForm.expenseCategory !== ExpenseCategory.OTHER ||
          (expenseForm.expenseCategory === ExpenseCategory.OTHER &&
            isNotEmpty(expenseForm.otherExpenseCategory))));

    const periodIsValid =
      !isCreatingNewExpense ||
      (isCreatingNewExpense &&
        isNotEmpty(expenseForm.period) &&
        (expenseForm.period !== Period.CUSTOM ||
          (expenseForm.period === Period.CUSTOM && isNotEmpty(expenseForm.endDate))));

    setFormIsValid(
      isNotEmpty(expenseForm.name) &&
        isNumber(expenseForm.amount) &&
        isNotEmpty(expenseForm.startDate) &&
        categoryIsValid &&
        periodIsValid
    );
  }, [expenseForm]);

  useEffect(() => {
    handleGetExpenseTypes();
    if (expenseId) {
      handleGetExpense(expenseId);
    }
    handleGetExpensesWithActivityAllocation();
  }, []);

  useEffect(() => {
    setAdministrativeActivityId(
      businessActivities.find((activity) => !activity.isProductionActivity)?.id
    );
  }, [businessActivities]);

  useEffect(() => {
    if (administrativeActivityId && !expenseId) {
      // Administrative activity id is fetched and expense is being created
      setExpenseForm((prev) => ({
        ...prev,
        activities: [
          {
            activityId: administrativeActivityId,
            allocation: 100
          }
        ]
      }));
    }
  }, [administrativeActivityId]);

  const deleteExpense = useDelete({
    getMessage: (expense: Expense) =>
      `Are you sure you want to permanently delete ${expense.name}?`,
    handleDelete: handleDeleteExpense,
    refetch: handleGetExpenses
  });

  return {
    expenseForm,
    formIsValid,
    handleFormChange,
    handleFormSubmit,
    loaders,
    error,
    handleFormToggleChange,
    expenseTypes,
    handleGetExpenses,
    expenses,
    dateRange,
    handleDateRangeChange,
    expenseId,
    businessActivities,
    expense,
    handleActivityAllocationChange,
    expensesActivityAllocation,
    reusedExpense,
    handleReuseExpenseAllocation,
    expensesSummary,
    sort: { ...sort, handleSort },
    deleteExpense
  };
};

export default useExpense;

import React, { SVGProps } from "react";

export const ContextAdd = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M17.1923 16.5H1.80775C1.30258 16.5 0.875 16.325 0.525 15.975C0.175 15.625 0 15.1974 0 14.6923V3.30775C0 2.80258 0.175 2.375 0.525 2.025C0.875 1.675 1.30258 1.5 1.80775 1.5H11.327V3H1.80775C1.73075 3 1.66025 3.03208 1.59625 3.09625C1.53208 3.16025 1.5 3.23075 1.5 3.30775V14.6923C1.5 14.7692 1.53208 14.8398 1.59625 14.9038C1.66025 14.9679 1.73075 15 1.80775 15H17.1923C17.2693 15 17.3398 14.9679 17.4038 14.9038C17.4679 14.8398 17.5 14.7692 17.5 14.6923V8.077H19V14.6923C19 15.1974 18.825 15.625 18.475 15.975C18.125 16.325 17.6974 16.5 17.1923 16.5ZM3.88475 12.673H10.25V10.1923H3.88475V12.673ZM3.88475 7.80775H10.25V5.327H3.88475V7.80775ZM12.6348 12.673H15.1152V8.077H12.6348V12.673ZM15.5 6V4H13.5V2.5H15.5V0.5H17V2.5H19V4H17V6H15.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

import Button from "components/Shared/Button";
import CurrencySymbol from "components/Shared/CurrencySymbol/CurrencySymbol";
import Dialog from "components/Shared/Dialog";
import Error from "components/Shared/Error";
import InputGroup from "components/Shared/InputGroup";
import TextInput from "components/Shared/TextInput";
import React from "react";
import { EditStoreProps, FormChangeEvent, StoreItemProduct, StoreItemRaw } from "types";
import MeasurementUnit from "../MeasurementUnit";
import Autocomplete from "components/Shared/Autocomplete";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";

export const RecordPurchaseDialog = (
  props: Pick<
    EditStoreProps,
    "error" | "measurementUnits" | "storeItemPurchase" | "dialog" | "setDialog"
  > & {
    storeItemType: "RAW_MATERIAL" | "PRODUCT" | undefined;
    storeItemUnit?: string;
    savingPurchase: boolean;
    storeItems?: (StoreItemRaw | StoreItemProduct)[];
  }
): React.ReactElement => {
  const {
    storeItemType,
    measurementUnits,
    savingPurchase,
    storeItemPurchase: {
      handlePurchaseFormChange,
      handlePurchaseFormSubmit,
      purchaseForm,
      error,
      handlePurchaseStoreItemChange
    },
    dialog,
    setDialog,
    storeItemUnit,
    storeItems
  } = props;
  const isProductStoreItem = storeItemType == "PRODUCT";
  return (
    <div className="">
      <Dialog
        isOpen={dialog == "purchase"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "purchase" : "");
        }}
        title={`Add new ${isProductStoreItem ? "batch" : "purchase"}`}>
        <form onSubmit={handlePurchaseFormSubmit} className="" data-testid="purchaseForm">
          <Error error={error} />
          {storeItems && (
            <div>
              <Autocomplete
                type="text"
                name="storeItem"
                onChange={(e: FormChangeEvent) => {
                  if (e.target.name == "storeItemId" && e.target.value) {
                    handlePurchaseStoreItemChange(
                      e,
                      storeItems.find((item) => item.id == e.target.value)
                    );
                  }
                }}
                value=""
                label="Select store item"
                dataIdName="storeItemId"
                data={storeItems.map((item) => ({
                  id: item.id,
                  name: getStoreItemName(item)
                }))}
                placeholder="Start typing to filter store items"
                disableNew
              />
            </div>
          )}
          <div>
            <InputGroup>
              <div className="w-auto flex-1">
                <TextInput
                  type="number"
                  name="measurement"
                  onChange={handlePurchaseFormChange}
                  value={purchaseForm.measurement}
                  required
                  label="Item measurement"
                  data-testid="measurementInput"
                />
              </div>
              <div className="w-28 md:w-60">
                <MeasurementUnit
                  disabled
                  id="unit"
                  name="unit"
                  required
                  onChange={handlePurchaseFormChange}
                  data-testid="purchaseMeasurementUnit"
                  className="bg-soft-grey"
                  label="Unit"
                  measurementUnit={purchaseForm.unit || storeItemUnit}
                  measurementUnits={measurementUnits}
                />
              </div>
            </InputGroup>
          </div>
          <div>
            <TextInput
              type="number"
              name="quantity"
              id="quantity"
              onChange={handlePurchaseFormChange}
              value={purchaseForm.quantity}
              required={true}
              label={`How many did you ${isProductStoreItem ? "produce" : "buy"}?`}
              data-testid="quantityInput"
              step={1}
            />
          </div>
          {!isProductStoreItem && (
            <div>
              <TextInput
                type="number"
                name="price"
                id="price"
                value={purchaseForm.price}
                required={true}
                onChange={handlePurchaseFormChange}
                label="Total amount"
                data-testid="amountInput"
                prepend={
                  <span className="text-xl">
                    <CurrencySymbol />
                  </span>
                }
              />
            </div>
          )}
          <Button
            type="submit"
            data-testid="purchaseSubmitButton"
            disabled={
              !purchaseForm.measurement ||
              !purchaseForm.quantity ||
              (!isProductStoreItem && !purchaseForm.price)
            }
            loading={savingPurchase}
            className="w-full mt-6">
            Save
          </Button>
        </form>
      </Dialog>
    </div>
  );
};

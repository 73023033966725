import Button from "components/Shared/Button";
import LoadingComponent from "components/Shared/Loading";
import Title from "components/Shared/Title";
import { useTemplatePurchase } from "hooks/useTemplatePurchase/useTemplatePurchase";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";

const TemplatePurchasePage = (): React.ReactElement => {
  const props = useTemplatePurchase();
  return (
    <LoadingComponent loading={props.loaders.purchasingTemplate || props.loaders.fetchingPurchase}>
      {props.successful && (
        <div
          className="w-full h-full flex flex-col items-center justify-center bg-white rounded-lg p-4 lg:p-10 mobile-full-screen"
          data-testid="noItems">
          <img src="/images/svg/webpage.svg" />
          <Title className="pt-10 pb-3 text-center">Template purchased successfully</Title>
          <p className="text-grey-50 px-4 text-center">
            You can now use this template to setup your own product
          </p>
          <div className="flex flex-col space-y-4 w-full md:w-auto items-center mt-4">
            <Link to={`/products/install/${props.templateId}`} data-testid="" className="md:w-auto">
              <Button
                type="button"
                className="!bg-black"
                gradient={false}
                icon={<Plus />}
                data-testid="">
                Continue to product setup
              </Button>
            </Link>
            <Link to="/templates/library" data-testid="" className="mt-4 link">
              I’ll do this later
            </Link>
          </div>
        </div>
      )}
    </LoadingComponent>
  );
};

export default TemplatePurchasePage;

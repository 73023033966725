import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { AUTOSAVE_INTERVAL } from "constants/general";
import {
  ADDON_ADDED,
  ADDON_REMOVED,
  ERROR_OCCURRED,
  FETCH_FAILED,
  REQUEST_SUCCESSFUL
} from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import deepEqual from "deep-equal";
import { track } from "helpers/analytics";
import { extractErrorMessage } from "helpers/api";
import { updateProperty } from "helpers/object";
import { useAddOns } from "hooks/useAddOns/useAddOns";
import { useEffect, useState } from "react";
import { useAutosave } from "react-autosave";
import { useParams } from "react-router-dom";
import {
  AddOnCostForm,
  FormChangeEvent,
  ObjectChanges,
  ProductAddOn,
  ProductVariantAddOn,
  ProductVariantAddOnDetails,
  Response
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

export const useProductAddOn = () => {
  const { productId } = useParams();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const addOnProps = useAddOns(productId);

  // UseStates
  const [addOnCostForm, setAddOnCostForm] = useState<AddOnCostForm>({
    amount: ""
  });
  const [productVariantAddOns, setProductVariantAddOns] = useState<ProductVariantAddOn[]>([]);
  const [savedProductVariantAddOns, setSavedProductVariantAddOns] = useState<ProductVariantAddOn[]>(
    []
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [error, setError] = useState("");
  const [variantsHaveChanges, setVariantsHaveChanges] = useState<ObjectChanges>({});
  useAutosave({
    data: productVariantAddOns,
    onSave: async (): Promise<void> => {
      if (hasUnsavedChanges) {
        saveAddOnCostMutation.mutate();
      }
    },
    interval: AUTOSAVE_INTERVAL,
    saveOnUnmount: true
  });

  const handleCostFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setAddOnCostForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleVariantAddOnsChange = (
    event: FormChangeEvent,
    variantId: string,
    addOnId: string
  ): void => {
    const { name, value } = event.target;
    setProductVariantAddOns((prev) => {
      return prev.map((variant) => {
        const addOnCosts = variant.addOnCosts.map((cost) =>
          updateProperty(cost.addOnId === addOnId, cost, { [name]: value })
        );
        return updateProperty(variant.variantId === variantId, variant, { addOnCosts });
      });
    });
    setError("");
  };

  const handleAddAddOn = async (addOnName: string): Promise<boolean> => {
    setError("");
    const requestData = {
      productId,
      name: addOnName
    };

    try {
      const json: Response<string> = await api.post("addon", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.ADDON_ADDED, {
          productId,
          addOnName
        });
        showAlert(AlertType.SUCCESS, ADDON_ADDED);
        await queryClient.invalidateQueries({ queryKey: ["product_addons"] });
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleRemoveAddOn = async (addOnId: string): Promise<boolean> => {
    setError("");
    try {
      const json: Response<void> = await api.delete(`addon/${addOnId}`).json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.ADDON_REMOVED, {
          productId,
          addOnId
        });
        showAlert(AlertType.SUCCESS, ADDON_REMOVED);
        await queryClient.invalidateQueries({ queryKey: ["product_addons"] });
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const saveAddOnCostMutation = useMutation({
    mutationFn: async () => {
      const requestData = {
        variants: productVariantAddOns.map(({ variantId, addOnCosts }) => ({
          variantId,
          addOnCosts: addOnCosts.map(({ addOnId, cost }) => ({
            cost: +cost,
            addOnId
          }))
        }))
      };

      const json: Response<string> = await api
        .put(`addon-cost/${productId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        await queryClient.invalidateQueries({ queryKey: ["product_addons"] });
      }
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const handleCostFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");
    saveAddOnCostMutation.mutate();
  };

  const handleGetProductAddOns = async (): Promise<ProductAddOn[]> => {
    try {
      const json: Response<ProductAddOn[]> = await api.get(`addon/${productId}`).json();
      if (json.code === 200) {
        return json.data;
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    return [];
  };

  const { data: productAddOns = [], isLoading: fetchingAddOnCost } = useQuery({
    queryKey: ["product_addons", productId],
    enabled: !!productId,
    queryFn: handleGetProductAddOns
  });

  const handleGetProductVariantAddOns = async (): Promise<ProductVariantAddOn[]> => {
    try {
      const json: Response<ProductVariantAddOnDetails> = await api
        .get(`addon-cost/${productId}`)
        .json();
      if (json.code === 200) {
        setSavedProductVariantAddOns(json.data.variants);
        // Set received addons to saved data if present
        setProductVariantAddOns((prev) => {
          return json.data.variants.map((variant) => {
            const existingProductVariant = prev.find(
              (oldVariant) => oldVariant.variantId === variant.variantId
            );
            return updateProperty(!!existingProductVariant, variant, {
              addOnCosts: variant.addOnCosts.map((cost) => {
                const existingCost = existingProductVariant?.addOnCosts.find(
                  (oldCost) => oldCost.addOnId == cost.addOnId
                );
                return !existingCost
                  ? cost
                  : {
                      ...cost,
                      cost: +existingCost.cost
                    };
              })
            });
          });
        });
        return json.data.variants;
      }
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  const { isLoading: fetchingProductVariantAddOns } = useQuery({
    queryKey: ["product_variant_addons", productId, productAddOns],
    enabled: !!productId,
    queryFn: handleGetProductVariantAddOns
  });

  // UseEffects
  useEffect(() => {
    setHasUnsavedChanges(!deepEqual(productVariantAddOns, savedProductVariantAddOns));
    const variantsHaveChanges: ObjectChanges = {};
    productVariantAddOns.forEach((variant) => {
      variantsHaveChanges[variant.variantId] = !deepEqual(
        variant,
        savedProductVariantAddOns.find(
          (savedVariant) => savedVariant.variantId == variant.variantId
        )
      );
    });
    setVariantsHaveChanges(variantsHaveChanges);
  }, [savedProductVariantAddOns, productVariantAddOns]);

  return {
    addOnCostForm,
    productAddOns,
    handleCostFormChange,
    handleCostFormSubmit,
    hasUnsavedChanges,
    handleVariantAddOnsChange,
    productId,
    handleAddAddOn,
    handleRemoveAddOn,
    productVariantAddOns,
    variantsHaveChanges,
    addOnProps,
    loaders: {
      fetchingAddOns:
        addOnProps.loaders.fetchingAddOns || fetchingAddOnCost || fetchingProductVariantAddOns,
      savingAddOns: addOnProps.loaders.savingAddOns || saveAddOnCostMutation.isPending
    },
    error
  };
};

export type UseProductAddOnType = ReturnType<typeof useProductAddOn>;

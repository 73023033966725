import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { WarningProps } from "types";

const Warning = (props: WarningProps) => {
  const { message, close, onClose } = props;
  return message ? (
    <div
      className="relative shadow flex items-center p-4 mb-5 text-sm bg-warning text-dark-warning rounded-lg pr-8"
      role="alert">
      <ExclamationTriangleIcon className="w-8 mr-2" />
      <span className="sr-only">Warning</span>
      <div data-testid="message">{message}</div>
      {close && (
        <span className="absolute right-2 top-2 p-2 cursor-pointer" onClick={onClose}>
          <span className="sr-only">Close Alert</span>
          <XMarkIcon className="w-5 h-5" />
        </span>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Warning;

import React, { SVGProps } from "react";

export const PlusSquare = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="mask0_3114_7347" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect x="0.5" y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3114_7347)">
        <path
          d="M11.75 17.25H13.25V13.25H17.25V11.75H13.25V7.75H11.75V11.75H7.75V13.25H11.75V17.25ZM5.80775 21C5.30258 21 4.875 20.825 4.525 20.475C4.175 20.125 4 19.6974 4 19.1923V5.80775C4 5.30258 4.175 4.875 4.525 4.525C4.875 4.175 5.30258 4 5.80775 4H19.1923C19.6974 4 20.125 4.175 20.475 4.525C20.825 4.875 21 5.30258 21 5.80775V19.1923C21 19.6974 20.825 20.125 20.475 20.475C20.125 20.825 19.6974 21 19.1923 21H5.80775ZM5.80775 19.5H19.1923C19.2692 19.5 19.3398 19.4679 19.4038 19.4038C19.4679 19.3398 19.5 19.2692 19.5 19.1923V5.80775C19.5 5.73075 19.4679 5.66025 19.4038 5.59625C19.3398 5.53208 19.2692 5.5 19.1923 5.5H5.80775C5.73075 5.5 5.66025 5.53208 5.59625 5.59625C5.53208 5.66025 5.5 5.73075 5.5 5.80775V19.1923C5.5 19.2692 5.53208 19.3398 5.59625 19.4038C5.66025 19.4679 5.73075 19.5 5.80775 19.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

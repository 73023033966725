import CreateStoreItem from "components/CreateStoreItem";
import CreateStoreItemProduct from "components/CreateStoreItemProduct";
import { CreateDialog } from "components/Shared/CreateDialog/CreateDialog";
import Dialog from "components/Shared/Dialog";
import LoadingComponent from "components/Shared/Loading";
import { TemplateConfiguration } from "components/TemplateConfiguration/TemplateConfiguration";
import { undefinedToString } from "helpers/string";
import { useProductsWithVariants } from "hooks/useProduct/useProductsWithVariants";
import useStoreItemProducts from "hooks/useStoreItemProducts";
import useStoreItems from "hooks/useStoreItems";
import { useTemplate } from "hooks/useTemplate/useTemplate";
import { useTemplateActivity } from "hooks/useTemplateActivities/useTemplateActivities";
import { useTemplateAddOn } from "hooks/useTemplateAddOn/useTemplateAddOn";
import { useTemplateComponent } from "hooks/useTemplateComponent/useTemplateComponent";
import { useTemplateConfiguration } from "hooks/useTemplateConfiguration/useTemplateConfiguration";
import ArrowLeft from "icons/ArrowLeft";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export const ConfigureTemplatePage = (): React.ReactElement => {
  const { loaders, template, templateId } = useTemplate();
  const { configuration } = useTemplateConfiguration();
  const templateComponents = useTemplateComponent(configuration?.variants || []);
  const templateAddons = useTemplateAddOn(configuration?.variants || []);
  const templateActivities = useTemplateActivity(configuration?.variants || []);
  const { productsWithVariants } = useProductsWithVariants();

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [fromProductIsOpen, setFromProductsIsOpen] = useState(false);
  const [rawIsOpen, setRawIsOpen] = useState(false);

  const storeItemArgs: [
    isEmbedded?: boolean,
    closeModals?: () => void,
    attachAsComponent?: { productId: string } | { templateId: string }
  ] = [
    true,
    () => {
      setRawIsOpen(false);
      setFromProductsIsOpen(false);
      setCreateIsOpen(false);
    },
    { templateId: undefinedToString(templateId) }
  ];
  const storeItemProps = useStoreItems(...storeItemArgs);
  const { storeItems, measurementUnits } = storeItemProps;
  const storeItemProductsProps = useStoreItemProducts(...storeItemArgs);

  return (
    <LoadingComponent loading={loaders.fetchingProduct && loaders.fetchingTemplate && !template}>
      {template && configuration && (
        <>
          <Link
            to={`/templates/published/${template.id}/variants`}
            className="font-bold text-sm	flex px-4 md:px-0">
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back to variants
          </Link>
          <TemplateConfiguration
            templateDetail={template}
            templateComponents={templateComponents}
            templateConfiguration={configuration}
            storeItems={storeItems}
            measurementUnits={measurementUnits}
            templateAddons={templateAddons}
            templateActivities={templateActivities}
            setCreateIsOpen={setCreateIsOpen}
          />
        </>
      )}
      <CreateDialog
        isOpen={createIsOpen}
        setIsOpen={setCreateIsOpen}
        title="How do you want to add your store item?"
        option1={{
          title: "From a Product",
          desc: "Create a store item from an existing product",
          onClick: () => setFromProductsIsOpen(true)
        }}
        option2={{
          title: "From Scratch",
          desc: "Create a raw store item from scratch",
          onClick: () => setRawIsOpen(true)
        }}
      />
      <Dialog
        isOpen={fromProductIsOpen}
        setIsOpen={setFromProductsIsOpen}
        titleClassName="text-center"
        title="Add from a product">
        <CreateStoreItemProduct
          {...storeItemProductsProps}
          measurementUnits={storeItemProps.measurementUnits}
          products={productsWithVariants}
        />
      </Dialog>
      <Dialog
        isOpen={rawIsOpen}
        setIsOpen={setRawIsOpen}
        titleClassName="text-center"
        title="Add from a raw material">
        <CreateStoreItem {...storeItemProps} />
      </Dialog>
    </LoadingComponent>
  );
};

import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { InfoProps } from "types";

const Info = (props: InfoProps) => {
  const { message, close, onClose } = props;
  return message ? (
    <div
      className="relative shadow flex items-center p-4 mb-5 text-sm text-[#e8f0fe] rounded-lg bg-[#769bde] pr-8"
      role="alert">
      <InformationCircleIcon className="w-8 mr-2" />
      <span className="sr-only">Info</span>
      <div data-testid="message">{message}</div>
      {close && (
        <span className="absolute right-2 top-2 p-2 cursor-pointer" onClick={onClose}>
          <span className="sr-only">Close Alert</span>
          <XMarkIcon className="w-5 h-5" />
        </span>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Info;

import ProductsComponent from "components/Products";
import LoadingComponent from "components/Shared/Loading";
import useProduct from "hooks/useProduct";
import React from "react";

const ProductsPage = (): React.ReactElement => {
  const {
    products,
    loaders,
    handleClone,
    handleCloneFormSubmit,
    handleFormChange,
    isOpen,
    setIsOpen,
    productForm,
    error,
    deleteProduct
  } = useProduct();

  return (
    <LoadingComponent loading={loaders.fetchingProduct && !products}>
      <ProductsComponent
        products={products}
        handleClone={handleClone}
        handleCloneFormSubmit={handleCloneFormSubmit}
        handleFormChange={handleFormChange}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loaders={loaders}
        productForm={productForm}
        error={error}
        deleteProduct={deleteProduct}
      />
    </LoadingComponent>
  );
};

export default ProductsPage;

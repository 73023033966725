import { Tab } from "@headlessui/react";
import { TabTitle } from "components/Shared/Tab/Tab";
import Title from "components/Shared/Title";
import { default as SettingsIcon } from "icons/Settings";
import Group from "icons/Group";
import React from "react";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Toggle from "components/Shared/Toggle";
import { SettingsProps } from "types";
import { FeatureFlag } from "types/enum";
import { findCurrencyDescription } from "helpers/currencies";
import CreditCard from "icons/CreditCard";

export const SettingsHeaderTab = ({
  selectedIndex
}: {
  selectedIndex: number;
}): React.ReactElement => {
  return (
    <div className="">
      <div className="flex flex-col md:flex-row items-start justify-between md:items-center space-y-4 md:space-y-0">
        <Title className="pb-0 md:pb-6">Settings</Title>
      </div>
      <div className="space-y-4">
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="bg-white flex text-grey-50 font-bold">
            <TabTitle
              title="General Settings"
              isSelected={selectedIndex == 0}
              icon={<SettingsIcon />}
              link="/settings"
            />
            <TabTitle
              title="Manage members"
              isSelected={selectedIndex == 1}
              icon={<Group />}
              link="/settings/members"
            />
            <TabTitle
              title="Payment methods"
              isSelected={selectedIndex == 2}
              icon={<CreditCard width={30} height={30} />}
              link="/settings/payments"
            />
          </Tab.List>
          <Tab.Panel></Tab.Panel>
        </Tab.Group>
      </div>
    </div>
  );
};

const Settings = ({
  handleFormChange,
  handleFormSubmit,
  handleToggleFeature,
  businessForm,
  storeEnabled,
  templatesEnabled,
  formIsValid,
  loaders
}: SettingsProps): React.ReactElement => {
  const currencyDescription = findCurrencyDescription(businessForm.currency);
  return (
    <div className="px-4 sm:px-0 pb-8">
      <div className="space-y-4">
        <SettingsHeaderTab selectedIndex={0} />
        <div className="bg-white sm:rounded-md p-4 lg:p-6">
          <p className="font-bold text-lg">Bussiness information</p>
          <form
            onSubmit={handleFormSubmit}
            className="mt-4 w-full lg:w-2/3"
            data-testid="onboardingForm">
            <Error error={""} />
            <div>
              <TextInput
                data-testid="businessName"
                type="businessName"
                name="businessName"
                onChange={handleFormChange}
                value={businessForm.businessName}
                required
                label="Business name"
              />
            </div>
            <div>
              <TextInput
                data-testid="businessType"
                type="businessType"
                name="businessType"
                value={businessForm.businessType}
                disabled
                label="Business category"
              />
            </div>
            <div>
              <TextInput
                data-testid="currency"
                type="currency"
                name="currency"
                value={`${currencyDescription?.name} (${currencyDescription?.symbol})`}
                disabled
                label="Currency"
              />
            </div>
            <div>
              <Button
                data-testid="submitButton"
                type="submit"
                loading={loaders.savingSettings}
                disabled={!formIsValid}
                className="w-full mt-4">
                Save changes
              </Button>
            </div>
          </form>
        </div>
        <div className="bg-white sm:rounded-md p-4 lg:p-6">
          <p className="font-bold text-lg">Show/hide features</p>
          <div>
            <Toggle
              checked={storeEnabled}
              onChange={() => handleToggleFeature(FeatureFlag.STORE)}
              name="storeEnabled"
              disabled={loaders.togglingFeature}
              label={
                <>
                  Show <b>store items</b> menu
                </>
              }
            />
          </div>
          {/* <div>
            <Toggle
              checked={templatesEnabled}
              name="templatesEnabled"
              onChange={() => handleToggleFeature(FeatureFlag.TEMPLATES)}
              disabled={loaders.togglingFeature}
              label={
                <>
                  Show <b>templates</b> menu
                </>
              }
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Settings;

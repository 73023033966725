import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { ERROR_OCCURRED, FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { isNotEmpty } from "helpers/validate";
import useDateRange from "hooks/shared/useDateRange";
import { useDelete } from "hooks/shared/useDelete/useDelete";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ActivityWithCost,
  ActivityForm,
  ActivityLoaders,
  UseActivityType,
  Response,
  FormChangeEvent,
  Activity,
  BusinessActivityWithCost
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useActivity = (): UseActivityType => {
  const { showAlert } = useAlert();
  const [searchParams] = useSearchParams();
  const { dateRange, handleDateRangeChange, getDateFilter } = useDateRange();
  const queryClient = useQueryClient();

  // UseStates
  const [activityForm, setActivityForm] = useState<ActivityForm>({
    name: ""
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<ActivityLoaders>({
    savingActivity: false,
    fetchingActivitites: true,
    deletingActivitites: true
  });
  const [error, setError] = useState("");
  const [activity, setActivity] = useState<ActivityWithCost>();
  const [isOpen, setIsOpen] = useState(searchParams.get("new") == "");

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setActivityForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleCreateActivity = async (): Promise<boolean> => {
    const requestData = {
      activity: activityForm.name
    };

    try {
      const json: Response<string> = await api.post("activity", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.ACTIVITY_ADDED, {
          activity: activityForm.name
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateActivity = async (): Promise<boolean> => {
    const requestData = {
      name: activityForm.name
    };

    try {
      const json: Response<void> = await api
        .put(`activity/${activity?.activityId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.ACTIVITY_MODIFIED, {
          activity: activityForm.name,
          activityId: activity?.activityId
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleDeleteActivity = async (activity: ActivityWithCost): Promise<boolean> => {
    if (!activity?.activityId) {
      return false;
    }
    try {
      const json: Response<void> = await api.delete(`activity/${activity.activityId}`).json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingActivity: true }));
    let result;
    if (activity) {
      result = await handleUpdateActivity();
    } else {
      result = await handleCreateActivity();
    }
    setLoaders((prev) => ({ ...prev, savingActivity: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      await queryClient.invalidateQueries({ queryKey: ["activity-with-cost"] });
      setIsOpen(false);
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const activityWithCostQuery = useQuery({
    queryFn: async (): Promise<ActivityWithCost[]> => {
      try {
        const json: Response<BusinessActivityWithCost> = await api
          .get(`activity/cost-details?${getDateFilter()}`)
          .json();
        if (json.code === 200) {
          return json.data.activities;
        }
      } catch (err) {
        showAlert(AlertType.DANGER, FETCH_FAILED);
        console.error(err);
      }
      return [];
    },
    queryKey: ["activity-with-cost", dateRange]
  });

  const businessActivityQuery = useQuery({
    queryFn: async () => {
      try {
        const json: Response<Activity[]> = await api.get("activity").json();
        if (json.code === 200) {
          return json.data.reverse();
        }
      } catch (err) {
        showAlert(AlertType.DANGER, FETCH_FAILED);
        console.error(err);
      }
      return [];
    },
    queryKey: ["business-activity"]
  });

  const resetActivityForm = (): void => {
    setActivityForm({ name: "" });
    setActivity(undefined);
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(isNotEmpty(activityForm.name));
  }, [activityForm]);

  useEffect(() => {
    setActivityForm({ name: activity?.name || "" });
  }, [activity]);

  const deleteActivity = useDelete({
    getMessage: (activity: ActivityWithCost) =>
      `Are you sure you want to permanently delete ${activity.name}?`,
    handleDelete: handleDeleteActivity,
    refetch: activityWithCostQuery.refetch
  });

  return {
    formIsValid,
    loaders: { ...loaders, fetchingActivitites: activityWithCostQuery.isFetching },
    handleFormChange,
    handleFormSubmit,
    error,
    activityForm,
    activities: activityWithCostQuery.data || [],
    dateRange,
    handleDateRangeChange,
    businessActivities: businessActivityQuery.data || [],
    setActivity,
    activity,
    resetActivityForm,
    isOpen,
    setIsOpen,
    deleteActivity
  };
};

export default useActivity;

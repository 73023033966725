import Amount from "components/Shared/Amount";
import IconButton from "components/Shared/IconButton";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import Title from "components/Shared/Title/Title";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";
import { TemplateCard } from "./TemplateCard/TemplateCard";
import { PublishedTemplatesProps } from "types";

export const PublishedTemplates = ({
  templateSummary
}: PublishedTemplatesProps): React.ReactElement => {
  return (
    <div className="">
      <Title className="pt-2 pb-6 px-4 md:px-0">
        Your Product Templates
        <InfoTooltip>These are the templates you have created from your products.</InfoTooltip>
      </Title>
      <div className="flex flex-col-reverse sm:flex-row justify-between items-start sm:items-center px-4 md:px-0">
        <p className=" text-lg font-semibold">
          {templateSummary?.published} published | {templateSummary?.drafts} draft(s)
        </p>

        <Link to="/templates/published/create" data-testid="createTemplate">
          <IconButton icon={<Plus />}>Create template</IconButton>
        </Link>
      </div>
      <div className="bg-white md:rounded-lg p-4 lg:p-6 mt-2 sm:mt-7">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 text-white">
          <div className="w-full rounded bg-gradient-to-r from-primary to-secondary p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base	font-semibold">Total downloads</p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">{templateSummary?.installs}</h2>
            </div>
          </div>
          <div className="w-full rounded bg-grey-60 p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base font-semibold">Total sales</p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">
                <Amount amount={templateSummary?.sales} />
              </h2>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-4">
          {templateSummary?.templates.map((template, index) => (
            <Link key={index} to={`/templates/published/${template.id}`} className="cursor-pointer">
              <TemplateCard template={template} />
            </Link>
          ))}
          {(!templateSummary || templateSummary?.templates.length === 0) && (
            <p>No templates found</p>
          )}
        </div>
      </div>
    </div>
  );
};

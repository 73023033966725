import { UseStoreItemsType } from "hooks/useStoreItems/useStoreItems";
import { UseTemplateType } from "hooks/useTemplate/useTemplate";
import { UseTemplateActivityType } from "hooks/useTemplateActivities/useTemplateActivities";
import { UseTemplateAddOnType } from "hooks/useTemplateAddOn/useTemplateAddOn";
import { UseTemplateComponentType } from "hooks/useTemplateComponent/useTemplateComponent";
import { UseTemplateInstallType } from "hooks/useTemplateInstall/useTemplateInstall";
import { UseTemplateLibraryType } from "hooks/useTemplateLibrary/useTemplateLibrary";
import { UseTemplatesType } from "hooks/useTemplates/useTemplates";
import {
  Product,
  ProductionActivity,
  ProductVariantActivity,
  ProductVariantActivityTime,
  ProductVariantAddOn
} from "./product";
import { UseTemplatePurchasesType } from "hooks/useTemplatePurchases/useTemplatePurchases";

export type TemplateForm = {
  name: string;
  productId: string;
  categories: string[];
  price: string;
  currency: string;
  description: string;
  images: TemplateImage[];
};

export type TemplateLoaders = {
  savingTemplate: boolean;
  fetchingTemplate: boolean;
  savingImage: boolean;
  uploadingImage: boolean;
  savingDesc: boolean;
};

export type TemplateValidity = {
  details: boolean;
  images: boolean;
  description: boolean;
};

export type CreateEditTemplateProps = UseTemplateType & {
  products: Product[];
};

interface MeasurementUnit {
  category: string;
  name: string;
  symbol: string;
}

export interface Component {
  id: string;
  name: string;
  storeItemType: string;
  measurementUnit: MeasurementUnit;
  linkedTemplateId?: string;
}

export interface Variant {
  id: string;
  name: string;
}

export interface Category {
  id: string;
  name: string;
}

interface BusinessType {
  id: string;
  name: string;
}

interface CreatedBy {
  id: string;
  name: string;
}

interface TemplateImage {
  url: string;
  isDefault: boolean;
  thumbnailUrl: string;
}

export interface Template {
  id: string;
  name: string;
  currency: string;
  businessId: string;
  price: string;
  createdBy: CreatedBy;
  description: string | null;
  installs: number;
  status: string;
  categories: Category[];
  businessTypes: BusinessType[];
  activities: TemplateActivity[];
  addOns: TemplatAddOnCosts[];
  components: Component[];
  images: TemplateImage[];
  variants: Variant[];
  linkedTemplates: Template[];
}

export interface TemplateSummaryItem {
  thumbnailUrl: string | null;
  price: number;
  status: string;
  name: string;
  categories: Category[];
  installs: number;
  owner?: string;
  id: string;
  author: CreatedBy;
}

export interface TemplateSummary {
  published: number;
  drafts: number;
  installs: number;
  sales: number;
  templates: TemplateSummaryItem[];
}

export interface PurchasedTemplateSummary {
  templates: TemplateSummaryItem[];
}

export type TemplatesLoaders = {
  fetchingTemplates: boolean;
};

export type PublishedTemplatesProps = UseTemplatesType;
export type PurchasedTemplatesProps = UseTemplatePurchasesType;

export type TemplateDetailProps = Omit<Required<UseTemplateType>, "template"> & {
  template: Template;
  library: boolean;
};

export type TemplateVariantsProps = Pick<UseTemplateType, "sort" | "deleteVariant"> & {
  template: Template;
};

export type ProductTemplateVariantForm = {
  name: string;
};

export type TemplateConfiguration = {
  productId: string;
  variants: TemplateVariant[];
};

export type TemplateVariant = {
  variantId: string;
  name: string;
  activityTimes: TemplateVariantActivityTime[];
  addOnCosts: TemplatAddOnCosts[];
  componentMeasurements: TemplateComponentVariantMeasurement[];
};

type TemplateVariantActivityTime = {
  productionActivityId: string;
  name: string;
  time: number;
  unit: string;
};

export type TemplatAddOnCosts = {
  id: string;
  name: string;
  businessAddOnId: string;
};

export type TemplateVariantAddOn = Pick<ProductVariantAddOn, "variantId" | "variantName"> & {
  addOnCosts: TemplatAddOnCosts[];
};

type TemplateComponentVariantMeasurement = {
  componentId: string;
  name: string;
  measurementValue: number;
  measurementUnit: string;
};

export type TemplateComponentVariant = {
  variantId: string;
  variantName: string;
  variantMeasurements: TemplateComponentVariantMeasurement[];
};

export type TemplateConfigurationProps = Pick<
  UseStoreItemsType,
  "storeItems" | "measurementUnits"
> & {
  templateDetail: Template;
  templateComponents: UseTemplateComponentType;
  templateConfiguration: TemplateConfiguration;
  templateAddons: UseTemplateAddOnType;
  templateActivities: UseTemplateActivityType;
  setCreateIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TemplateComponentProps = Pick<UseStoreItemsType, "storeItems" | "measurementUnits"> &
  UseTemplateComponentType &
  Pick<Template, "components" | "name"> & {
    setCreateIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };

export type TemplateAddOnProps = UseTemplateAddOnType & {
  name: string;
  templateAddOns: TemplatAddOnCosts[];
};

export type TemplateVariantActivity = Pick<ProductVariantActivity, "variantId" | "variantName"> & {
  activityTimes: (ProductVariantActivityTime & { name: string })[];
};

export type TemplateActivityProps = UseTemplateActivityType & {
  name: string;
  templateActivities: TemplateActivity[];
};

export type TemplateActivity = ProductionActivity;

export type BusinessTypeCategory = {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  businessTypes: string[];
  noOfTemplates: number;
};

export type TemplateLibraryCategoryProps = UseTemplateLibraryType;

export type InstallProductTemplateProps = UseTemplateInstallType;

export type ReviewTemplateProps = InstallProductTemplateProps;

// Install

export enum TemplateConfigItemType {
  DIRECT = "DIRECT",
  LINKED = "LINKED"
}

export type OnboardTemplateArgs = {
  components: OnboardTemplateComponentArgs[];
  activities: OnboardTemplateActivitiesArgs[];
  addOns: OnboardTemplateAddOnsArgs[];
  linkedTemplates: LinkedTemplateArgs[];
  templateId: string;
};

export type OnboardTemplateComponentArgs = {
  type: TemplateConfigItemType; // <-- indicates that this component links to another template in the linked templates array
  id: string;
  replaced: boolean;
  storeItemId?: string; // <-- pass only if replaced is true
  linkedTemplateId?: string;
};

export type OnboardTemplateActivitiesArgs = {
  id: string;
  replaced: boolean;
  businessActivityId?: string; // <-- pass only if replaced is true
};

export type OnboardTemplateAddOnsArgs = {
  id: string;
  replaced: boolean;
  businessAddOnId?: string; // <-- pass only if replaced is true
};

export type LinkedTemplateArgs = {
  templateId: string;
  components: LinkedTemplateComponentArgs[];
  activities: OnboardTemplateActivitiesArgs[];
  addOns: OnboardTemplateAddOnsArgs[];
};

// NOTE: no type for components in linked template
// they must all the raw material store items, so can't be linked
// this is to prevent the tree exceeding 2 layers
export type LinkedTemplateComponentArgs = {
  id: string;
  replaced: boolean;
  storeItemId?: string;
};

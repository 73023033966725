import MeasurementUnit from "components/EditStoreItem/MeasurementUnit";
import Accordion from "components/Shared/Accordion";
import Amount from "components/Shared/Amount";
import IconButton from "components/Shared/IconButton";
import InputGroup from "components/Shared/InputGroup";
import TextInput from "components/Shared/TextInput";
import ArrowLeft from "icons/ArrowLeft";
import ArrowRight from "icons/ArrowRight";
import Info from "icons/Info";
import React, { useState } from "react";
import { VariantComponentsProps } from "types";

export const VariantComponents = ({
  variants,
  variantsHaveChanges,
  measurementUnits,
  handleVariantComponentsChange,
  storeItems,
  components
}: VariantComponentsProps): React.ReactElement => {
  const [activeKey, setActiveKey] = useState("");

  return (
    <>
      {variants.map(
        (variant, variantIndex): React.ReactElement => (
          <Accordion
            key={variant.variantId}
            itemId={variant.variantId}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            header={
              <div className="inline-flex items-center justify-between flex-1 pr-6">
                <span className="inline-flex items-center">
                  <b data-testid="variantName">{variant.variantName}</b>
                  {variant.variantMeasurements.filter(
                    (measurement) => measurement.measurementValue == 0
                  ).length > 0 && (
                    <span
                      className="ml-4 text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center"
                      data-testid="emptyStoreItems">
                      <Info /> &nbsp;
                      {
                        variant.variantMeasurements.filter(
                          (measurement) => measurement.measurementValue == 0
                        ).length
                      }
                    </span>
                  )}
                </span>
                {!variantsHaveChanges[variant.variantId] && variant.totalCost !== undefined && (
                  <Amount amount={variant.totalCost} />
                )}
              </div>
            }>
            <div className="flex flex-col">
              {variant.variantMeasurements.map((measurement) => {
                const storeItem = storeItems?.items.find(
                  (item) =>
                    components.find((component) => component.id == measurement.componentId)
                      ?.storeItemId == item.id
                );
                return (
                  <div
                    className="flex justify-between items-center pt-2 px-2 md:px-4  border-b border-grey-30"
                    key={measurement.componentId}
                    data-testid="part">
                    <span
                      data-testid="partName"
                      className="flex-1 flex gap-1 items-center font-semibold">
                      {measurement.name}
                      <span className="text-grey-50 text-sm">
                        <Amount amount={storeItem?.costPerUnit} />/{storeItem?.unit.symbol}
                      </span>
                    </span>
                    <div className="flex-1 max-w-xs">
                      <InputGroup>
                        <div className="w-auto flex-1">
                          <TextInput
                            type="number"
                            name="measurementValue"
                            inputSize="sm"
                            onChange={(e): void =>
                              handleVariantComponentsChange(
                                e,
                                variant.variantId,
                                measurement.componentId
                              )
                            }
                            value={measurement.measurementValue}
                            label="Measurement"
                            data-testid="measurementValue"
                            min={0}
                          />
                        </div>
                        <div className="w-16 md:w-20">
                          <MeasurementUnit
                            id="unit"
                            inputSize="sm"
                            name="measurementUnit"
                            value={measurement.measurementUnit}
                            required
                            onChange={(e): void =>
                              handleVariantComponentsChange(
                                e,
                                variant.variantId,
                                measurement.componentId
                              )
                            }
                            data-testid="measurementUnit"
                            className="bg-soft-grey"
                            label="Unit"
                            measurementUnit={measurement.measurementUnit}
                            measurementUnits={measurementUnits}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                );
              })}
              <div className="flex justify-between items-center py-2 px-4 border-grey-30 bg-soft-grey text-primary">
                <div>
                  {variantIndex > 0 && (
                    <IconButton
                      size="sm"
                      onClick={() => setActiveKey(variants[variantIndex - 1].variantId)}
                      icon={<ArrowLeft />}
                      data-testid="prev"
                      outlined>
                      Previous
                    </IconButton>
                  )}
                </div>
                <div>
                  {variantIndex < variants.length - 1 && (
                    <IconButton
                      size="sm"
                      onClick={() => setActiveKey(variants[variantIndex + 1].variantId)}
                      iconEnd={<ArrowRight />}
                      data-testid="next"
                      outlined>
                      Next variant
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          </Accordion>
        )
      )}
    </>
  );
};
